<template>
  <vx-card :title="this.title ? this.title : 'My Pictures'" class="mt-base pictures-holder">
    <div class="vx-row pt-2 pb-2 my-pictures" v-if="!isLoading">
        <div class="ml-2" v-if="myFeedPhotos.length === 0">No pictures posted yet.</div>
        <div class="vx-col w-1/3 sm:w-1/3 md:w-1/3 xl:1/4 cursor-pointer" 
          v-for="(data, index) in myFeedPhotos" 
          :key="index"
          @click="activateImageSlide(data.image, data.postID)">
            <img :src="data.image" alt="latest-upload" class="rounded mb-4 user-latest-image responsive">
        </div>
    </div>
    <Loading v-else/>
    <vs-popup title="My Pictures" :active.sync="slideActive">
      <div class="lg:flex">
        <img :src="imgSrc" alt="latest-upload" class="rounded mb-4 lg:w-1/2 w-full slide-image responsive my-pictures-image">
        <div class="lg:w-1/2 w-full lg:ml-4">
          <PostHeader :feed="currentPost" :displayPostOption="false" :displayLikeAndShare="true" :displayShare="true" />
          <PostContent :feed="currentPost" :showImages="false"/>
          <vs-divider />
          <Comment v-if="currentPost.comments.items" :data="currentPost.comments.items" :feed="currentPost" :enableCommentInput="true" />
        </div>
      </div>
    </vs-popup>
  </vx-card>
</template>

<script>
import Loading from './Loading'
import Post from './Post/Post'
import PostHeader from './Post/PostHeader'
import PostContent from './Post/PostContent'
import Comment from './Comment'

export default {
  name: 'PictureHolder',
  components: {
    Loading,
    Post,
    PostHeader,
    PostContent,
    Comment
  },
  data () {
    return {
      slideActive: false,
      imgSrc: '',
      currentPost: {
        content: '',
        mediaFiles: [],
        comments: {
          items: []
        },
        likes: {
          items: []
        },
        authorRef: {
          photoURL: ''
        }
      }
    }
  },
  props: ['isLoading', 'title'],
  computed: {
    myFeedPhotos () {
      return this.$store.getters['profile/photoURLs']
    },
    myFeeds () {
      return this.$store.state.profile.myFeeds
    }
  },
  methods: {
    activateImageSlide (img, postID) {
      this.slideActive = true
      this.imgSrc = img
      this.currentPost = this.myFeeds.filter(feed => feed.id === postID)[0]
    }
  }
}
</script>

<style scoped>
/* .user-latest-image {
  width: 75px;
  height: 75px;
  object-fit: cover;
  cursor: pointer !important;
} */

.pictures-holder {
  /* height: 400px; */
}
.loading-pictures {
  height: 200px !important;
}

.slide-image {
  width: 300px;
  height: auto;
}

</style>
