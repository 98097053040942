<template>
  <vx-card
    :title="this.title ? this.title : 'My Posts'"
    class="mt-base my-posts"
  >
  <div class="">
    <ul v-if="myFeeds.length !== 0" class="twitter-feeds-list">
      <li
        class="twitter-feed"
        :class="{ 'mt-8': index }"
        v-for="(feed, index) in myFeeds"
        :key="feed.id"
      >
        <div class="twitter-feed-header flex items-center">
          <vs-avatar class="m-0" :src="feed.authorRef.photoURL" size="35px" />
          <div class="leading-tight ml-3">
            <p class="feed-author font-semibold">
              {{ feed.authorRef.displayName }}
            </p>
            
            <p class="flex items-center">
              <span class="text-sm" v-if="!feed.authorRef.isPartner">{{ feed.authorRef.studyStatus }}</span>
              <span class="text-sm" v-else>{{ feed.partnerData.partnerType | partnerTypeFormatter }}</span>
              <feather-icon
                v-if="feed.authorRef.isUniEmailVerify"
                class="ml-1"
                icon="CheckIcon"
                svgClasses="h-3 w-3 bg-primary rounded-full text-white"
              ></feather-icon
            ></p>
            <p class="text-sm">
              posted in {{ feed.channelName }} channel
            </p>
          </div>
        </div>
        <p
          class="post-content mt-4 mb-2"
          v-html="$options.filters.formatPostContent(feed.content)"
        ></p>
        <ul class="flex post-media-list">
          <li
            class="post-media m-1"
            v-for="(media, mediaIdex) in feed.mediaFiles.slice(0, 2)"
            :key="mediaIdex"
          >
            <img
              class="responsive rounded post-image"
              :src="media"
              alt="user-upload"
            />
          </li>
        </ul>
        <Post v-if="feed.sharePostID" :feed="feed.sharePost" @onClick="goToSinglePost(feed.sharePostID)"
          class="cursor-pointer"
          :actor="''"
          :action="''"
          :isUniEmailVerify="feed.authorRef.isUniEmailVerify"
          :displayComment="false"
          :displayPostOption="false"
          :displayLikeAndShare="false"
          :displayShare="false"
          :displayLikeAndCommentCount="false" />
        <div v-if="feed.ogMetaData && feed.ogMetaData.items && feed.ogMetaData.items.length !== 0" @click="goToWebsite(feed.ogMetaData.items[0].requestUrl)">
          <vs-card class="cursor-pointer">
            <div class="static">
              <div>
                <!-- <div slot="media" v-if="ogMetaData.ogVideoUrl && ogMetaData.ogVideoType ">
                  <iframe class="border-none w-full og-video-player" :src="ogMetaData.ogVideoUrl" />
                </div> -->
                <div slot="media" v-if="feed.ogMetaData.items[0].ogImageUrl">
                  <img :src="feed.ogMetaData.items[0].ogImageUrl" class="og-img"/>
                </div>
                <div>
                  <h5 class="mt-4 mb-2 font-medium">{{ feed.ogMetaData.items[0].ogTitle | truncate(50) }}</h5>
                  <span v-if="feed.ogMetaData.items[0].ogSiteName">{{ feed.ogMetaData.items[0].ogSiteName }}</span>
                  <p class="text-base whitespace-pre-wrap">{{ feed.ogMetaData.items[0].ogDescription }}</p>
                </div>
              </div>
            </div>
          </vs-card>
        </div>
        <small class="mt-3 inline-block">{{ new Date(feed.createdAt) | date(true) }}</small>
        <vs-divider />
      </li>
    </ul>
    <p v-else>Loading &#128522;...</p>
  </div>
  </vx-card>
</template>

<script>
import Post from '@/layouts/components/coursepal/Post/Post'

export default {
  name: 'MyFeeds',
  props: {
    title: {
      type: String
    },
    author: {
      type: String,
      required: true
    }
  },
  components: {
    Post
  },
  computed: {
    myFeeds () {
      
     return this.$store.state.profile.myFeeds
    }
  },
  methods: {
    goToSinglePost (postId) {
      this.$router.push(`/post/?postId=${postId}`)
    }
  },
  created () {
    
    this.$store.dispatch('profile/fetchMyFeeds', this.author)

   
  }
}
</script>

<style lang="css" scoped>
.post-content {
  white-space: break-spaces;
}
/* .post-image {
  object-fit: cover;
  width: 80px;
  height: 80px;
} */
</style>